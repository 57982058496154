import { endpointPrefixV1 as V1 } from "@config";
import { BranchesMassAction, GroupsMassAction } from "types/entities";

const authPrefix = "/rest-api/oauth";

export const ENDPOINTS = {
  public: {
    login: `${authPrefix}/access_token`,
    signup: `${V1}/signup`,
    guestSignup: `${V1}/guest-signup`,
    verifySignUp: `${V1}/signup/verify`,
    passwordReset: `${V1}/reset-password`,
    passwordCreateValidate: `${V1}/password/validate`,
    passwordCreate: `${V1}/password`,
    changePassword: `${V1}/force-update-password`,
    refreshToken: `${authPrefix}/refresh_token`,
    requestPasswordCreateResend: `${V1}/password/resend`,
    domainSettings: `${V1}/domain/settings`,
    ssoDomainSettings: `${V1}/domain/settings/sso/auth-urls`,
    socialDomainSettings: `${V1}/domain/settings/social`,
    ldapSsoDomainSettings: `${V1}/domain/settings/sso/auth-urls/ldap`,
    autologin: `${V1}/autologin`,
    requestPasswordReset: (login: string) => `${V1}/reset-password/${login}`,
    autologinToken: (token: string) => `${V1}/autologin/${token}`,
    branchAutologin: (branchId: string) => `${V1}/branch/${branchId}/switch-with-credentials`,
  },
  analytics: {
    trackEvent: `${V1}/analytics`,
  },
  processEvent: `${V1}/process-event`,
  accountAndSettings: {
    security: {
      security: `${V1}/domain/settings/security`,
    },
    export: {
      availableTypes: `${V1}/export/types`,
    },
    import: {
      import: `${V1}/import`,
    },
    ftpSync: {
      serverConfigEndpoint: `${V1}/ftp/config/server`,
      exportConfigEndpoint: `${V1}/ftp/config/export`,
      importConfigEndpoint: `${V1}/ftp/config/import`,
      testConnection: `${V1}/ftp/testconnection`,
      exportNow: `${V1}/ftp/export`,
      importNow: `${V1}/ftp/import`,
    },
    courseSettings: {
      courseSettings: `${V1}/courses/settings`,
      customCourseFields: `${V1}/domain/courses/custom-fields`,
      customCourseFieldsReorder: `${V1}/domain/courses/custom-fields/order`,
      customCourseFieldsId: (id: string) => `${V1}/domain/courses/custom-fields/${id}`,
      customCourseFieldUniqueName: `${V1}/domain/courses/custom-fields/check-name`,
      customCertificates: `${V1}/certificate-templates/list-view`,
      customCertificateDefaultTemplateBody: `${V1}/certificate-templates/template`,
      customCertificatesSmartTags: `${V1}/certificate-templates/smart-tags`,
      customCertificateBackgrounds: `${V1}/certificate-templates/backgrounds`,
      customCertificateTemplate: (id: string) => `${V1}/certificate-templates/${id}`,
      resetCustomCertificate: (id: string) => `${V1}/certificate-templates/${id}/reset`,
      deleteCustomCertificateBackground: (name: string) =>
        `${V1}/certificate-templates/backgrounds/${name}`,
      previewCustomCertificate: (name: string) =>
        `${V1}/certificate-templates/backgrounds/${name}/preview`,
      createCustomCertificate: `${V1}/certificate-templates`,
      cloneCustomCertificate: (id: string) => `${V1}/certificate-templates/${id}/clone`,
      affectedCourses: (id: string) => `${V1}/certificate-templates/${id}/courses/count`,
    },
    customHomepage: {
      customHomepage: `${V1}/cms`,
      branchCustomHomepage: (branchId: string) => `${V1}/branches/${branchId}/cms`,
      publishCustomHomepage: (cmsId: string) => `${V1}/cms/${cmsId}`,
      cloneCustomHomepage: (cmsId: string) => `${V1}/cms/${cmsId}/clone`,
      customHomepageCourses: (cmsId: string) => `${V1}/cms/${cmsId}/courses`,
      customHomepagePageId: (cmsId: string, pageId: string) => `${V1}/cms/${cmsId}/pages/${pageId}`,
      customHomepagePageSectionId: (cmsId: string, pageId: string, sectionId: string) =>
        `${V1}/cms/${cmsId}/pages/${pageId}/sections/${sectionId}`,
      customHomepagePageIdSections: (cmsId: string, pageId: string) =>
        `${V1}/cms/${cmsId}/pages/${pageId}/sections`,
      customHomepagePageIdSectionsOrder: (cmsId: string, pageId: string) =>
        `${V1}/cms/${cmsId}/pages/${pageId}/sections/reorder`,
      customHomepagePages: (cmsId: string) => `${V1}/cms/${cmsId}/pages`,
      customHomepagePagesOrder: (cmsId: string) => `${V1}/cms/${cmsId}/pages/reorder`,
      customHomepageVideo: (cmsId: string) => `${V1}/cms/${cmsId}/video`,
    },
    ecommerce: {
      settings: `${V1}/ecommerce`,
      credits: `${V1}/ecommerce/credits`,
      users: `${V1}/ecommerce/users`,
      groups: `${V1}/ecommerce/groups`,
      branches: `${V1}/ecommerce/branches`,
      invoices: `${V1}/ecommerce/invoices`,
      downloadInvoice: (id: string) => `${V1}/ecommerce/invoices/${id}`,
      coupons: `${V1}/ecommerce/coupons`,
      singleCoupon: (id: string) => `${V1}/ecommerce/coupons/${id}`,
      courses: `${V1}/ecommerce/courses`,
      categories: `${V1}/ecommerce/categories`,
    },
    portalSettings: {
      portalSettings: `${V1}/domain/settings/portal`,
      domainNameSettings: (name: string) => `${V1}/domain/name/${name}`,
      customSSL: `${V1}/domain/custom-ssl`,
      customDomainSettings: `/rest-api/v1/domain/custom-domain`,
      customDomainName: (name: string) => `/rest-api/v1/domain/custom-domain/${name}`,
      customDomainNameValidate: (name: string) =>
        `/rest-api/v1/domain/custom-domain/validate/${name}`,
      customEmailSettings: `/rest-api/v1/domain/custom-email-server`,
      customEmailSettingsValidate: `/rest-api/v1/domain/custom-email-server/validate`,
      generateCSRKey: `/rest-api/v1/domain/generate-csr`,
      skipCommunications: `${V1}/users/me/skip-communications`,
    },
    integrationsSettings: {
      integrations: `${V1}/integrations`,
      tlmsapi: `${V1}/integrations/tlmsapi`,
      xApi: `${V1}/integrations/xapi`,
      intercom: `${V1}/integrations/intercom`,
      bambooHR: `${V1}/integrations/bamboohr`,
      conferences: `${V1}/integrations/conferences`,
      stripe: `${V1}/integrations/stripe`,
      stripeConnect: `${V1}/integrations/stripe/connect-url`,
      paypal: `${V1}/integrations/paypal`,
      javascript: (integration: string) => `${V1}/integrations/javascript/${integration}`,
    },
    usersSettings: {
      generateSCIMKey: `${V1}/domain/settings/sso/generate-scim-key`,
      customUserFields: `${V1}/domain/users/fields`,
      customUserFieldsReorder: `${V1}/domain/users/fields/reorder`,
      customUserFieldsId: (id: string) => `${V1}/domain/users/fields/${id}`,
      usersSettings: `${V1}/domain/settings/users`,
      languageOverridesCode: (languageCode: string) =>
        `${V1}/domain/language_overrides/${languageCode}`,
      languageOverridesCodeCount: (languageCode: string) =>
        `${V1}/domain/language_overrides/${languageCode}/count`,
      languageOverridesCodeExport: (languageCode: string) =>
        `${V1}/domain/language_overrides/${languageCode}/export`,
      languageOverridesId: (id: string) => `${V1}/domain/language_override/${id}`,
      ssoConfiguration: `${V1}/domain/settings/sso/configuration/url`,
      ssoConfigurationData: (token: string) => `${V1}/domain/settings/sso/configuration/${token}`,
      ssoSettings: `${V1}/domain/settings/sso`,
    },
    subscription: {
      updateCreditCard: `${V1}/domain/settings/credit-card`,
      billingAddress: `${V1}/domain/settings/billing-address`,
      cancelSubscription: `${V1}/domain/settings/subscription/cancel`,
      invoices: `${V1}/domain/settings/invoices`,
      downloadInvoice: (invoiceId: string) =>
        `${V1}/domain/settings/invoices/${invoiceId}/download`,
      previewInvoice: (invoiceId: string) => `${V1}/domain/settings/invoices/${invoiceId}`,
      currentPlan: `${V1}/domain/settings/subscription`,
      reactivateSubscription: `${V1}/domain/settings/subscription/reactivate`,
      downgradeToFree: `${V1}/domain/settings/subscription/downgrade-to-free`,
      updateRecipients: `${V1}/domain/settings/invoices/recipients`,
      plans: `${V1}/domain/settings/plans`,
      planPricingDetails: `${V1}/domain/settings/subscription/estimate-charges`,
      couponValidation: `${V1}/domain/settings/subscription/coupons/validation`,
    },
  },
  courses: {
    availableGroups: (courseId: string): string => `${V1}/courses/${courseId}/groups/available`,
    availableBranches: (courseId: string) => `${V1}/courses/${courseId}/branches/available`,
    branches: (courseId: string): string => `${V1}/courses/${courseId}/branches`,
    catalogCourseUnits: (courseId: string) => `${V1}/catalog/courses/${courseId}/units`,
    clone: (courseId: string): string => `${V1}/courses/${courseId}/clone`,
    contentTokens: `${V1}/content/tokens`,
    course: (courseId: string): string => `${V1}/courses/${courseId}`,
    courseFiles: (courseId: string): string => `${V1}/courses/${courseId}/files`,
    courses: `${V1}/courses`,
    coursesLight: `${V1}/courses/light`,
    copyFromDomainLogin: `${V1}/courses/copy-from-domain/login`,
    copyFromDomainList: `${V1}/courses/copy-from-domain/courses-list`,
    copyFromDomain: `${V1}/courses/copy-from-domain`,
    customFields: `${V1}/courses/custom-fields`,
    delete: (courseId: string) => `${V1}/courses/${courseId}`,
    edit: (courseId: string): string => `${V1}/courses/${courseId}`,
    files: (courseId: string): string => `${V1}/courses/${courseId}/files`,
    filesEdit: (courseId: string, fileId: string): string =>
      `${V1}/courses/${courseId}/files/${fileId}`,
    filesDelete: (courseId: string, filedId: string): string =>
      `${V1}/courses/${courseId}/files/${filedId}`,
    filesUpdate: (courseId: string, filedId: number): string =>
      `${V1}/courses/${courseId}/files/${filedId}`,
    filesUpload: (courseId: string): string => `${V1}/courses/${courseId}/files`,
    filesUploadURL: (courseId: string): string => `${V1}/courses/${courseId}/files/url`,
    groups: (courseId: string) => `${V1}/courses/${courseId}/groups`,
    image: (courseId: string): string => `${V1}/courses/${courseId}/image`,
    instructors: (courseId: string) => `${V1}/courses/${courseId}/instructors`,
    introVideoDelete: (courseId: string): string => `${V1}/courses/${courseId}/intro_video`,
    introVideoUpload: (courseId: string): string => `${V1}/courses/${courseId}/intro_video`,
    myCourseUnits: (courseId: string) => `${V1}/courses/${courseId}/units`,
    myCourses: `${V1}/users/me/courses`,
    prerequisiteOptions: (courseId: string): string =>
      `${V1}/courses/${courseId}/rules/prerequisite-options`,
    publish: (courseId: string) => `${V1}/courses/${courseId}/publish`,
    rating: (courseId: string): string => `${V1}/courses/${courseId}/rating`,
    rulesEdit: (courseId: string): string => `${V1}/courses/${courseId}/rules`,
    sectionCreate: `${V1}/sections`,
    selfUnenroll: (courseId: string): string => `${V1}/courses/${courseId}/unenrollment`,
    shared: (courseId: string): string => `${V1}/courses/${courseId}/shared`,
    sharedNotification: (courseId: string): string =>
      `${V1}/courses/${courseId}/shared-notification`,
    unenroll: (courseId: string): string => `${V1}/courses/${courseId}/unenrollment`,
    unitUpload: (courseId: string): string => `${V1}/courses/${courseId}/units/files`,
    users: (courseId: string): string => `${V1}/courses/${courseId}/users`,
    unenrolledUsers: (courseId: string): string => `${V1}/courses/${courseId}/users/unassigned`,
    user: {
      edit: (courseId: string, userId: string): string =>
        `${V1}/courses/${courseId}/users/${userId}`,
      syncProgress: (courseId: string, userId: string, removeCertificate?: boolean): string =>
        `${V1}/courses/${courseId}/users/${userId}/sync-progress${
          removeCertificate ? "?remove-certificate=true" : ""
        }`,
      resetProgress: (courseId: string, userId: string, removeCertificate?: boolean): string =>
        `${V1}/courses/${courseId}/users/${userId}/reset-progress${
          removeCertificate ? "?remove-certificate=true" : ""
        }`,
      completeCourse: (courseId: string, userId: string): string =>
        `${V1}/courses/${courseId}/users/${userId}/complete`,
    },
    youtubeSearch: `${V1}/youtube-search`,
    // units
    getLinkedUnits: (unitId: string) => `${V1}/units/${unitId}/linked`,
    unitAssignment: {
      submit: (unitId: string) => `${V1}/units/${unitId}/submission`,
      fileUpload: (unitId: string) => `${V1}/units/${unitId}/submission/file`,
    },
    unitClone: (unitId: string) => `${V1}/units/${unitId}/clone`,
    unitComplete: (unitId: string) => `${V1}/units/${unitId}/complete`,
    unitCompleteWithQuestion: (unitId: string) => `${V1}/units/${unitId}/complete/question`,
    unitCreate: `${V1}/units`,
    unitDelete: (unitId: string, shouldDeleteLinkedUnits?: boolean) =>
      `${V1}/units/${unitId}${shouldDeleteLinkedUnits ? "?delete-linked=true" : ""}`,
    unitDeleteBookmarks: (unitId: string) => `${V1}/units/${unitId}/bookmarks`,
    unitEnter: (unitId: string) => `${V1}/units/${unitId}/enter`,
    unitExit: (unitId: string) => `${V1}/units/${unitId}/exit`,
    unitExport: (unitId: string) => `${V1}/units/${unitId}/export`,
    unitIlt: {
      cloneSession: (sessionId: string) => `${V1}/sessions/${sessionId}/clone`,
      createSession: `${V1}/sessions`,
      deleteSession: (sessionId: string) => `${V1}/sessions/${sessionId}`,
      unitSessions: (unitId: string) => `${V1}/units/${unitId}/sessions`,
      register: (sessionId: string, userId: string) =>
        `${V1}/sessions/${sessionId}/add-user/${userId}`,
      unregister: (sessionId: string, userId: string) =>
        `${V1}/sessions/${sessionId}/remove-user/${userId}`,
      updateSession: (sessionId: string) => `${V1}/sessions/${sessionId}`,
    },
    unitLink: (unitId: string) => `${V1}/units/${unitId}/link`,
    unitMigrate: (unitId: string) => `${V1}/units/${unitId}/migrate`,
    unitsOrder: (courseId: string) => `${V1}/courses/${courseId}/units/order`,
    unitPassword: (unitId: string) => `${V1}/units/${unitId}/start/password`,
    unitPublish: (unitId: string) => `${V1}/units/${unitId}/publish`,
    unitResults: (unitId: string) => `${V1}/units/${unitId}/results`,
    unitSmartTags: `${V1}/units/smart-tags`,
    unitSnapshot: (unitId: string) => `${V1}/units/${unitId}/start/snapshot`,
    unitStart: (unitId: string) => `${V1}/units/${unitId}/start`,
    unit: (unitId: string) => `${V1}/units/${unitId}`,
    unitUpdate: (unitId: string) => `${V1}/units/${unitId}`,
    unitVersionHistory: (unitId: string) => `${V1}/units/${unitId}/versions`,
    // tests
    addExistingQuestion: (unitId: string, questionId: string) =>
      `${V1}/units/${unitId}/questions/${questionId}`,
    createTestQuestion: (unitId: string) => `${V1}/units/${unitId}/questions`,
    importQuestions: (unitId: string) => `${V1}/units/${unitId}/questions/import`,
    importQuestionPreview: (unitId: string) => `${V1}/units/${unitId}/questions/import-preview`,
    deleteTestQuestion: (questionId: string) => `${V1}/questions/${questionId}`,
    getAllQuestions: `${V1}/questions`,
    questionAppearances: (unitId: string, questionId: string) =>
      `${V1}/units/${unitId}/questions/${questionId}/appearances`,
    questionsTags: `${V1}/questions/tags`,
    questionsUsages: (questionId: string) => `${V1}/questions/${questionId}/usages`,
    questionsWeights: (unitId: string) => `${V1}/units/${unitId}/questions/weights`,
    reorderQuestions: (unitId: string) => `${V1}/units/${unitId}/questions/order`,
    setDelayAvailability: (sectionId: string) => `${V1}/units/${sectionId}/delay`,
    testQuestion: (unitId: string, questionId: string) =>
      `${V1}/units/${unitId}/questions/${questionId}`,
    testQuestions: (unitId: string) => `${V1}/units/${unitId}/questions`,
    toggleRandomizedQuestion: (randomizedQuestionId: string) =>
      `${V1}/questions/${randomizedQuestionId}/toggle`,
    submitTestQuestion: (unitId: string, questionId: string) =>
      `${V1}/units/${unitId}/questions/${questionId}/submit`,
    removeExistingQuestion: (unitId: string, questionId: string) =>
      `${V1}/units/${unitId}/questions/${questionId}`,
    resetTest: (unitId: string) => `${V1}/units/${unitId}/reset`,
    getTestQuestion: (questionId: string) => `${V1}/questions/${questionId}`,
    updateTestQuestion: (questionId: string) => `${V1}/questions/${questionId}`,
    massActions: {
      courses: `${V1}/courses/mass-actions`,
      coursesCount: `${V1}/courses/mass-actions/count`,
      courseUsers: (courseId: string) => `${V1}/courses/${courseId}/users/mass-actions`,
      courseUsersCount: (courseId: string) => `${V1}/courses/${courseId}/users/mass-actions/count`,
    },
  },
  catalog: {
    catalog: `${V1}/catalog/courses`,
    course: (courseId: string): string => `${V1}/catalog/courses/${courseId}`,
    courseFiles: (courseId: string): string => `${V1}/catalog/courses/${courseId}/files`,
    categories: `${V1}/catalog/categories`,
    bundles: `${V1}/catalog/bundles`,
    settings: `${V1}/catalog/settings`,
    sampleUnit: (unitId: string) => `${V1}/catalog/sampleunits/${unitId}`,
    purchase: `${V1}/catalog/purchase`,
    enrollment: `${V1}/catalog/enrollment`,
    subscribe: `${V1}/catalog/subscribe`,
    publicCourse: (key: string): string => `${V1}/courses/shared/${key}`,
  },
  user: {
    profile: `${V1}/users/me`,
    files: `${V1}/users/me/files`,
    password: `${V1}/users/me/password`,
    updateProfile: `${V1}/users/me/profile`,
    avatar: `${V1}/users/me/avatar`,
    certificates: `${V1}/users/me/certificates`,
    badges: `${V1}/users/me/badges`,
    rewards: `${V1}/users/me/rewards`,
    customFields: `${V1}/users/custom_fields`,
    statistics: `${V1}/users/me/statistics`,
    groups: `${V1}/users/me/groups`,
    joinGroup: `${V1}/groups/join`,
    integrations: `${V1}/users/me/integrations`,
    branches: `${V1}/users/me/branches`,
    singleUser: (userId: string) => `${V1}/users/${userId}`,
    activateUser: `${V1}/users/activate-on-signup`,
  },
  messages: {
    singleMessage: (messageId: number) => `${V1}/messages/${messageId}`,
    inbox: `${V1}/messages/inbox`,
    sent: `${V1}/messages/sent`,
    createMessage: `${V1}/messages`,
    reply: (messageId: number) => `${V1}/messages/${messageId}/reply`,
    forward: (messageId: number) => `${V1}/messages/${messageId}/forward`,
    delete: (messageId: number) => `${V1}/messages/${messageId}`,
    recipients: `${V1}/messages/recipients`,
    read: (messageId: number) => `${V1}/messages/${messageId}/read`,
    unread: (messageId: number) => `${V1}/messages/${messageId}/unread`,
    attachment: `${V1}/messages/attachment`,
    unreadMessages: `${V1}/messages/unread`,
  },
  discussions: {
    discussions: `${V1}/discussions`,
    active: `${V1}/discussions/active`,
    audience: `${V1}/discussions/audience`,
    singleDiscussion: (discussionId: number) => `${V1}/discussions/${discussionId}`,
    replies: (discussionId: number) => `${V1}/discussions/${discussionId}/replies`,
    createDiscussion: `${V1}/discussions`,
    attachment: `${V1}/discussions/attachment`,
    comment: (discussionId: number) => `${V1}/discussions/${discussionId}/replies`,
    commentReply: (discussionId: number) => `${V1}/discussions/replies/${discussionId}`,
    edit: (discussionId: number) => `${V1}/discussions/${discussionId}`,
    subscribe: (discussionId: number) => `${V1}/discussions/${discussionId}/subscribe`,
    unsubscribe: (discussionId: number) => `${V1}/discussions/${discussionId}/unsubscribe`,
    editCommentReply: (commentId: number) => `${V1}/discussions/replies/${commentId}`,
    like: (discussionId: number) => `${V1}/discussions/replies/${discussionId}/like`,
    unlike: (discussionId: number) => `${V1}/discussions/replies/${discussionId}/unlike`,
    delete: (discussionId: number) => `${V1}/discussions/${discussionId}`,
    deleteComment: (discussionId: number) => `${V1}/discussions/replies/${discussionId}`,
  },
  referral: {
    rewards: `${V1}/referral-program/reward`,
    reward: (rewardId: number) => `${V1}/referral-program/reward/${rewardId}`,
    referrerInfo: `${V1}/referral-program/referrer/info`,
    tos: `${V1}/referral-program/tos`,
    availability: `${V1}/referral-program/availability`,
    referrer: `${V1}/referral-program/referrer`,
  },
  reverseTrial: {
    enable: `${V1}/reverse-trial/enable`,
    hasTalentLibraryCollection: `${V1}/reverse-trial/talent-library-collection`,
  },
  dashboard: {
    today: `${V1}/dashboard/today`,
    todayInstructor: `${V1}/dashboard/today/instructor`,
    dontMissLearner: `${V1}/dashboard/dontmiss`,
    dontMissInstructor: `${V1}/dashboard/dontmiss/instructor`,
    portalActivity: `${V1}/dashboard/widgets/portal-activity`,
    coursePie: `${V1}/dashboard/widgets/course-pie`,
    overview: `${V1}/dashboard/widgets/overview`,
    timeline: `${V1}/dashboard/widgets/timeline`,
    smallestCompletionRate: `${V1}/dashboard/widgets/low-completion-courses`,
    users: `${V1}/dashboard/widgets/users`,
    recentCourseActivity: `${V1}/dashboard/widgets/course-activity`,
  },
  gamification: {
    badges: `${V1}/gamification/badges`,
    badge: (badgeId: string) => `${V1}/gamification/badges/${badgeId}`,
    badgeSets: `${V1}/badges/sets`,
    updateActiveBadgeSet: `${V1}/gamification/badges/set`,
    leaderboard: `${V1}/gamification/leaderboard`,
    settings: `${V1}/gamification/settings`,
    settingsReset: `${V1}/gamification/settings/reset`,
    statisticsReset: `${V1}/gamification/statistics/reset`,
  },
  search: {
    spotlight: `${V1}/spotlight`,
  },
  conferences: {
    conferencesList: `${V1}/conferences`,
    create: `${V1}/conferences`,
    delete: (conferenceId: string) => `${V1}/conferences/${conferenceId}`,
    join: (id: string) => `${V1}/conferences/${id}/join`,
    notify: (conferenceId: string) => `${V1}/conferences/${conferenceId}/notify`,
    update: (conferenceId: string) => `${V1}/conferences/${conferenceId}`,
    users: (id: string) => `${V1}/conferences/${id}/users`,
    uninvitedUsers: (id: string) => `${V1}/conferences/${id}/users/uninvited`,
    userAdd: (conferenceId: string, userId: string) =>
      `${V1}/conferences/${conferenceId}/users/${userId}`,
    userRemove: (conferenceId: string, userId: string) =>
      `${V1}/conferences/${conferenceId}/users/${userId}`,
  },
  calendar: {
    events: `${V1}/calendar/events`,
    event: (eventId: number) => `${V1}/calendar/events/${eventId}`,
    export: `${V1}/calendar/export`,
    sync: `${V1}/calendar/sync`,
    specificAudience: `${V1}/calendar/audience`,
    statistics: `${V1}/calendar/statistics`,
  },
  branches: {
    affectedBranches: (action: BranchesMassAction, courseId: number) =>
      `${V1}/branches/courses/${courseId}/chunks?action=${action}`,
    branchesByUsers: `${V1}/branches/by-users`,
    branch: (branchId: string) => `${V1}/branches/${branchId}`,
    courseAction: (branchId: string, courseId: string) =>
      `${V1}/branches/${branchId}/courses/${courseId}`,
    courses: (branchId: string) => `${V1}/branches/${branchId}/courses`,
    availableCourses: (branchId: string) => `${V1}/branches/${branchId}/courses/available`,
    userAction: (branchId: string, userId: string) => `${V1}/branches/${branchId}/users/${userId}`,
    users: (branchId: string) => `${V1}/branches/${branchId}/users`,
    availableUsers: (branchId: string) => `${V1}/branches/${branchId}/users/available`,
    branches: `${V1}/branches`,
    testSSOConfig: (branchId: string) => `${V1}/branches/${branchId}/sso/configuration/url`,
    ssoConfigurationData: (branchId: string, token: string) =>
      `${V1}/branches/${branchId}/sso/configuration/${token}`,
    clone: (branchId: string) => `${V1}/branches/${branchId}/clone`,
    files: (branchId: string): string => `${V1}/branches/${branchId}/files`,
    filesEdit: (branchId: string, fileId: string): string =>
      `${V1}/branches/${branchId}/files/${fileId}`,
    filesUpload: (branchId: string): string => `${V1}/branches/${branchId}/files`,
    filesUploadURL: (branchId: string): string => `${V1}/branches/${branchId}/files/url`,
    massAction: (courseId: number) => `${V1}/branches/courses/${courseId}`,
    switch: (branchId: string) => `${V1}/branch/${branchId}/switch`,
  },
  files: {
    filesTags: `${V1}/files/tags`,
    register: (fileId: number) => `${V1}/files/${fileId}/register-download`,
  },
  groups: {
    files: (groupId: string): string => `${V1}/groups/${groupId}/files`,
    filesEdit: (groupId: string, fileId: string): string =>
      `${V1}/groups/${groupId}/files/${fileId}`,
    filesUpload: (groupId: string): string => `${V1}/groups/${groupId}/files`,
    filesUploadURL: (groupId: string): string => `${V1}/groups/${groupId}/files/url`,
    groups: `${V1}/groups`,
    groupsByUsers: `${V1}/groups/by-users`,
    group: (groupId: string) => `${V1}/groups/${groupId}`,
    users: (groupId: string) => `${V1}/groups/${groupId}/users`,
    addUsersToGroupCoursesCount: (groupId: string) => `${V1}/groups/${groupId}/users/courses/count`,
    addUsersToGroupCourses: (groupId: string) => `${V1}/groups/${groupId}/users/courses`,
    removeUsersFromGroupCoursesCount: (groupId: string) =>
      `${V1}/groups/${groupId}/users/courses/mass-delete/count`,
    removeUsersFromGroupCourses: (groupId: string) =>
      `${V1}/groups/${groupId}/users/courses/mass-delete`,
    availableUsers: (groupId: string) => `${V1}/groups/${groupId}/users/available`,
    groupOwner: (groupId: string, userId: string) =>
      `${V1}/groups/${groupId}/users/${userId}/owner`,
    userAction: (groupId: string, userId: string) => `${V1}/groups/${groupId}/users/${userId}`,
    userCoursesAction: (groupId: string, userId: string) =>
      `${V1}/groups/${groupId}/users/${userId}/courses`,
    courseAction: (groupId: string, courseId: string) =>
      `${V1}/groups/${groupId}/courses/${courseId}`,
    courses: (groupId: string) => `${V1}/groups/${groupId}/courses`,
    availableCourses: (groupId: string) => `${V1}/groups/${groupId}/courses/available`,
    affectedGroups: (type: GroupsMassAction, courseId: number) =>
      `${V1}/groups/courses/${courseId}/chunks?action=${type}`,
    massAction: (courseId: number) => `${V1}/groups/courses/${courseId}`,
  },
  announcements: {
    announcements: `${V1}/announcements`,
    close: `${V1}/announcements/close`,
  },
  domainTerms: {
    terms: `${V1}/domain/terms`,
    accept: `${V1}/users/me/terms`,
  },
  categories: {
    categories: `${V1}/categories`,
    create: `${V1}/categories`,
    edit: (categoryId: string) => `${V1}/categories/${categoryId}`,
    delete: (categoryId: string) => `${V1}/categories/${categoryId}`,
  },
  eventsEngine: {
    branches: `${V1}/eventsengine/filter/branches`,
    courses: `${V1}/eventsengine/filter/courses`,
    groups: `${V1}/eventsengine/filter/groups`,
    userTypes: `${V1}/eventsengine/filter/usertypes`,
  },
  automations: {
    automations: `${V1}/automations`,
    automation: (automationId: string) => `${V1}/automations/${automationId}`,
    delete: (automationId: string) => `${V1}/automations/${automationId}`,
    conditions: `${V1}/automations/conditions`,
    create: `${V1}/automations`,
    edit: (automationId: string) => `${V1}/automations/${automationId}`,
  },
  notifications: {
    notifications: `${V1}/notifications`,
    deleteNotificationItem: (notificationId: string) => `${V1}/notifications/${notificationId}`,
    getNotification: (notificationId: string) => `${V1}/notifications/${notificationId}`,
    editNotificationItem: (notificationId: string) => `${V1}/notifications/${notificationId}`,
    previewNotification: `${V1}/notifications/preview`,
    postNotificationSmartTags: `${V1}/notifications/smarttags`,
    notificationHistory: `${V1}/notifications/sent`,
    notificationHistoryItem: (notificationHistoryId: string) =>
      `${V1}/notifications/sent/${notificationHistoryId}`,
    resend: (notificationHistoryId: string) =>
      `${V1}/notifications/sent/${notificationHistoryId}/resend`,
    deleteNotificationHistory: `${V1}/notifications/sent`,
    notificationsPending: `${V1}/notifications/pending`,
    deleteNotificationsPending: `${V1}/notifications/pending`,
    notificationPendingItem: (notificationPendingId: string) =>
      `${V1}/notifications/pending/${notificationPendingId}`,
    deleteNotificationPendingItem: (notificationPendingId: string) =>
      `${V1}/notifications/pending/${notificationPendingId}`,
    systemNotifications: `${V1}/notifications/system`,
    systemNotification: (type: string) => `${V1}/notifications/system/${type}`,
    systemNotificationSmartTags: (type: string) => `${V1}/notifications/system/${type}/smarttags`,
    editSystemNotification: (type: string) => `${V1}/notifications/system/${type}`,
    previewSystemNotification: (type: string) => `${V1}/notifications/system/${type}/preview`,
    resetSystemNotification: (type: string) => `${V1}/notifications/system/${type}/reset`,
  },
  themes: {
    themes: `${V1}/themes`,
    create: `${V1}/themes`,
    active: (themeId: string) => `${V1}/themes/${themeId}/active`,
    edit: (themeId: string) => `${V1}/themes/${themeId}`,
    delete: (themeId: string) => `${V1}/themes/${themeId}`,
  },
  coupons: {
    validation: `${V1}/coupon/validation`,
  },
  users: {
    activateCount: `${V1}/users/activate/count`,
    activateMassAction: `${V1}/users/activate`,
    addUsersToBranchesCount: `${V1}/branches/users/count`,
    addUsersToBranches: `${V1}/branches/users`,
    addUsersToGroupsCount: `${V1}/groups/users/count`,
    addUsersToGroups: `${V1}/groups/users`,
    assignableTypes: `${V1}/users/types/assignable`,
    deactivateCount: `${V1}/users/deactivate/count`,
    deactivateMassAction: `${V1}/users/deactivate`,
    deleteCount: `${V1}/users/mass-delete/count`,
    deleteMassAction: `${V1}/users/mass-delete`,
    removeUsersFromBranchesCount: `${V1}/branches/users/mass-delete/count`,
    removeUsersFromBranches: `${V1}/branches/users/mass-delete`,
    removeUsersFromGroupsCount: `${V1}/groups/users/mass-delete/count`,
    removeUsersFromGroups: `${V1}/groups/users/mass-delete`,
    avatar: (userId: string) => `${V1}/users/${userId}/avatar`,
    badges: (userId: string) => `${V1}/users/${userId}/badges`,
    files: (userId: string): string => `${V1}/users/${userId}/files`,
    filesEdit: (userId: string, fileId: string): string => `${V1}/users/${userId}/files/${fileId}`,
    filesUpload: (userId: string): string => `${V1}/users/${userId}/files`,
    filesUploadURL: (userId: string): string => `${V1}/users/${userId}/files/url`,
    user: (userId: string) => `${V1}/users/${userId}`,
    users: `${V1}/users`,
    courses: (userId: string) => `${V1}/users/${userId}/courses`,
    groups: (userId: string) => `${V1}/users/${userId}/groups`,
    branches: (userId: string) => `${V1}/users/${userId}/branches`,
    availableBranches: (userId: string) => `${V1}/users/${userId}/branches/available`,
    availableGroups: (userId: string) => `${V1}/users/${userId}/groups/available`,
    availableCourses: (userId: string) => `${V1}/users/${userId}/courses/available`,
    units: (userId: string) => `${V1}/reports/users/${userId}/units`,
    completeUserUnit: (unitId: string, userId: string) =>
      `${V1}/units/${unitId}/users/${userId}/complete`,
    resetUserUnit: (unitId: string, userId: string) =>
      `${V1}/units/${unitId}/users/${userId}/reset`,
    impersonation: (userId: string) => `${V1}/users/${userId}/impersonate`,
    stopImpersonation: `${V1}/users/me/stop-impersonation`,
    payments: (userId: string) => `${V1}/users/${userId}/invoices`,
    singleUserCertificates: (userId: string) => `${V1}/users/${userId}/certificates`,
  },
  userTypes: {
    userTypes: `${V1}/user-types`,
    edit: (userTypeId: number) => `${V1}/user-types/${userTypeId}`,
    delete: (userTypeId: number) => `${V1}/user-types/${userTypeId}/remove`,
    export: `${V1}/user-types/export`,
  },
  widgetsDashboard: {
    widgets: `${V1}/users/me/dashboard/widgets`,
    widgetList: `${V1}/dashboard/widgets`,
  },
  reports: {
    courseUsersProgress: (courseId: string, userId: string) =>
      `${V1}/reports/courses/${courseId}/users/${userId}/progress`,
    unitMatrix: (courseId: string) => `${V1}/reports/courses/${courseId}/unit-matrix`,
    trainingMatrix: `${V1}/reports/training-matrix`,
    timeline: `${V1}/reports/timeline`,
    deleteEvent: (eventId: string) => `${V1}/reports/timeline/${eventId}/destroy`,
    restoreEvent: (eventId: string) => `${V1}/reports/timeline/${eventId}/restore`,
    courses: `${V1}/reports/courses`,
    certificates: {
      delete: (userId: string, certificateId: string) =>
        `${V1}/users/${userId}/certificates/${certificateId}`,
    },
    users: `${V1}/reports/users`,
    branches: `${V1}/reports/branches`,
    groups: `${V1}/reports/groups`,
    unitReports: `${V1}/reports/units`,
    customReports: `${V1}/reports/custom`,
    customReport: (reportId: string) => `${V1}/reports/custom/${reportId}`,
    customReportRun: (reportId: string) => `${V1}/reports/custom/run/${reportId}`,
    unitUsers: (unitId: string) => `${V1}/reports/units/${unitId}/users`,
    unitTimeline: (unitId: string) => `${V1}/reports/units/${unitId}/timeline`,
    courseUnits: (courseId: string): string => `${V1}/reports/courses/${courseId}/units`,
    branchUsers: (branchId: string) => `${V1}/reports/branches/${branchId}/users`,
    branchCourses: (branchId: string) => `${V1}/reports/branches/${branchId}/courses`,
    branchTimeline: (branchId: string) => `${V1}/reports/branches/${branchId}/timeline`,
    branchInformation: (branchId: string) => `${V1}/reports/branches/${branchId}`,
    branchActivityWidget: (branchId: string) =>
      `${V1}/reports/branches/${branchId}/widgets/activity`,
    courseActivityWidget: (courseId: string) =>
      `${V1}/reports/courses/${courseId}/widgets/activity`,
    userActivityWidget: (userId: string) => `${V1}/reports/users/${userId}/widgets/activity`,
    groupActivityWidget: (groupId: string) => `${V1}/reports/groups/${groupId}/widgets/activity`,
    groupUsers: (groupId: string) => `${V1}/reports/groups/${groupId}/users`,
    groupCourses: (groupId: string) => `${V1}/reports/groups/${groupId}/courses`,
    groupTimeline: (groupId: string) => `${V1}/reports/groups/${groupId}/timeline`,
    courseTimeline: (courseId: string) => `${V1}/reports/courses/${courseId}/timeline`,
    courseInformation: (courseId: string) => `${V1}/reports/courses/${courseId}`,
    courseUsers: (courseId: string) => `${V1}/reports/courses/${courseId}/users`,
    userTimeline: (userId: string) => `${V1}/reports/users/${userId}/timeline`,
    scheduledReports: `${V1}/reports/scheduled`,
    scheduledReport: (reportId: string) => `${V1}/reports/scheduled/${reportId}`,
    userCourses: (userId: string) => `${V1}/reports/users/${userId}/courses`,
    completeUserCourse: (courseId: string, userId: string) =>
      `${V1}/courses/${courseId}/users/${userId}/complete`,
    resetUserCourse: (courseId: string, userId: string) =>
      `${V1}/courses/${courseId}/users/${userId}/reset`,
    myProgressCourses: `${V1}/reports/users/me/courses`,
    myProgressUnits: `${V1}/reports/users/me/units`,
    myProgressTimeline: `${V1}/reports/users/me/timeline`,
    massActions: {
      unitsCompleteCount: (unitId: string) => `${V1}/units/${unitId}/users/complete/count`,
      unitsResetCount: (unitId: string) => `${V1}/units/${unitId}/users/reset/count`,
      unitsComplete: (unitId: string) => `${V1}/units/${unitId}/users/complete`,
      unitsReset: (unitId: string) => `${V1}/units/${unitId}/users/reset`,
    },
    patchScormTitle: (unitId: string, questionId: string) =>
      `${V1}/reports/scorms/${unitId}/questions/${questionId}`,
    resetScormTitle: (unitId: string, questionId: string) =>
      `${V1}/reports/scorms/${unitId}/questions/${questionId}/reset`,
    patchScormAnswer: (unitId: string, questionId: string, answerId: string) =>
      `${V1}/reports/scorms/${unitId}/questions/${questionId}/answers/${answerId}`,
    resetScormAnswer: (unitId: string, questionId: string, answerId: string) =>
      `${V1}/reports/scorms/${unitId}/questions/${questionId}/answers/${answerId}/reset`,
    instructorReply: (unitId: string, userId: string) =>
      `${V1}/reports/assignments/${unitId}/users/${userId}/instructor-reply`,
    scormAnalysis: (unitId: string) => `${V1}/reports/scorms/${unitId}/analysis`,
    testAnalysis: (unitId: string) => `${V1}/reports/tests/${unitId}/analysis`,
    surveyAnalysis: (unitId: string) => `${V1}/reports/surveys/${unitId}/analysis`,
    freeTextAnswers: (unitId: string, questionId: string) =>
      `${V1}/reports/tests/${unitId}/questions/${questionId}/answers`,
    freeTextAnswersSurvey: (unitId: string, questionId: string) =>
      `${V1}/reports/surveys/${unitId}/questions/${questionId}/answers`,
    assignmentProgress: (unitId: string, userId: string) =>
      `${V1}/reports/assignments/${unitId}/users/${userId}/progress`,
    iltSessions: (unitId: string) => `${V1}/reports/ilts/${unitId}/sessions`,
    testAttempt: (unitId: string, userId: string) =>
      `${V1}/reports/tests/${unitId}/users/${userId}/attempts`,
    surveyAttempt: (unitId: string, userId: string) =>
      `${V1}/reports/surveys/${unitId}/users/${userId}/attempts`,
    scormAttempt: (unitId: string, userId: string) =>
      `${V1}/reports/scorms/${unitId}/users/${userId}/attempts`,
    markQuestionCorrect: (unitId: string, attemptId: string, questionId: string) =>
      `${V1}/reports/tests/${unitId}/attempts/${attemptId}/questions/${questionId}/mark-correct`,
    progressWidget: (unitId: string) => `${V1}/reports/units/${unitId}/widgets/progress`,
    activityWidget: (unitId: string) => `${V1}/reports/units/${unitId}/widgets/activity`,
    userSubmissionAssignment: (token: string) => `${V1}/reports/assignments/progress/${token}`,
    userSubmissionTest: (token: string) => `${V1}/reports/tests/attempts/${token}`,
    userSubmissionSurvey: (token: string) => `${V1}/reports/surveys/attempts/${token}`,
    sessionsFilters: (unitId: string) => `${V1}/filters/ilts/${unitId}/sessions`,
    groupProgress: (groupId: string) => `${V1}/reports/groups/${groupId}/widgets/progress`,
  },
  microstats: {
    unitMicrostats: (type: string, id: string): string =>
      `${V1}/reports/units-microstats/${type}/${id}`,
    reportsMicrostats: (type: string): string => `${V1}/reports/microstats/${type}`,
    singleReportsMicrostats: (type: string, id: string): string =>
      `${V1}/reports/microstats/${type}/${id}`,
  },
  certificates: {
    templates: `${V1}/certificate-templates`,
    templatePreview: (templateId: string) => `${V1}/certificate-templates/${templateId}/preview`,
    preview: (courseId: string, userId: string) =>
      `${V1}/courses/${courseId}/users/${userId}/certificate/preview`,
    update: (courseId: string, userId: string) =>
      `${V1}/courses/${courseId}/users/${userId}/certificate`,
    delete: (courseId: string, userId: string) =>
      `${V1}/courses/${courseId}/users/${userId}/certificate`,
    countMassUpdate: (courseId: string) =>
      `${V1}/courses/${courseId}/certificates/mass-update-count`,
    massUpdate: (courseId: string) => `${V1}/courses/${courseId}/certificates/mass-update`,
    download: (token: string) => `${V1}/certificate/download/${token}`,
  },
  customReports: {
    delete: (reportId: string) => `${V1}/reports/custom/${reportId}`,
    clone: (reportId: string) => `${V1}/reports/custom/${reportId}/clone`,
    filterBranches: `${V1}/filters/branches`,
    filterCourses: `${V1}/filters/courses`,
    filterGroups: `${V1}/filters/groups`,
    filterUserTypes: `${V1}/filters/user-types`,
    filterUserCustomFields: `${V1}/filters/users/custom-fields`,
    filterCourseCustomFields: `${V1}/filters/courses/custom-fields`,
    massActions: {
      branchAdd: (reportId: string, branchId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/branches/${branchId}/add`,
      branchAddCount: (reportId: string, branchId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/branches/${branchId}/add/count`,
      branchRemove: (reportId: string, branchId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/branches/${branchId}/remove`,
      branchRemoveCount: (reportId: string, branchId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/branches/${branchId}/remove/count`,
      courseAdd: (reportId: string, courseId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/courses/${courseId}/enroll`,
      courseAddCount: (reportId: string, courseId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/courses/${courseId}/enroll/count`,
      courseRemove: (reportId: string, courseId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/courses/${courseId}/unenroll`,
      courseRemoveCount: (reportId: string, courseId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/courses/${courseId}/unenroll/count`,
      groupAdd: (reportId: string, groupId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/groups/${groupId}/add`,
      groupAddCount: (reportId: string, groupId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/groups/${groupId}/add/count`,
      groupRemove: (reportId: string, groupId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/groups/${groupId}/remove`,
      groupRemoveCount: (reportId: string, groupId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/groups/${groupId}/remove/count`,
      resetProgressOnCourse: (reportId: string, courseId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/courses/${courseId}/reset-progress`,
      resetProgressOnCourseCount: (reportId: string, courseId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/courses/${courseId}/reset-progress/chunks`,
      sendMessage: (reportId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/send-message`,
      usersActivate: (reportId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/users/activate`,
      usersActivateCount: (reportId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/users/activate/count`,
      usersDeactivate: (reportId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/users/deactivate`,
      usersDeactivateCount: (reportId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/users/deactivate/count`,
      deleteUsers: (reportId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/users/delete`,
      deleteUsersCount: (reportId: string) =>
        `${V1}/reports/custom/run/${reportId}/mass-actions/users/delete/count`,
    },
  },
  tableExports: {
    categories: `${V1}/categories/export`,
    courses: `${V1}/reports/courses/exports`,
    coursesTable: `${V1}/courses/export`,
    courseFiles: (courseId: string) => `${V1}/courses/${courseId}/files/export`,
    courseUsers: (courseId: string) => `${V1}/courses/${courseId}/users/export`,
    courseBranches: (courseId: string) => `${V1}/courses/${courseId}/branches/export`,
    courseGroups: (courseId: string) => `${V1}/courses/${courseId}/groups/export`,
    customCourseFields: `${V1}/domain/courses/custom-fields/export`,
    customUserFields: `${V1}/domain/users/fields/export`,
    courseTimeline: (courseId: string) => `${V1}/reports/courses/${courseId}/timeline/exports`,
    reportsBranchUsers: (branchId: string) => `${V1}/reports/branches/${branchId}/users/exports`,
    branchCourses: (branchId: string) => `${V1}/reports/branches/${branchId}/courses/exports`,
    branchTimeline: (branchId: string) => `${V1}/reports/branches/${branchId}/timeline/exports`,
    existingQuestions: (unitId: string) => `${V1}/units/${unitId}/questions/export`,
    groupUsers: (groupId: string) => `${V1}/groups/${groupId}/users/export`,
    groupFiles: (groupId: string) => `${V1}/groups/${groupId}/files/export`,
    groupUsersReports: (groupId: string) => `${V1}/reports/groups/${groupId}/users/exports`,
    groupCourses: (groupId: string) => `${V1}/reports/groups/${groupId}/courses/exports`,
    groupsReport: `${V1}/reports/groups/exports`,
    timelineExport: `${V1}/reports/timeline/exports`,
    groupTimeline: (groupId: string) => `${V1}/reports/groups/${groupId}/timeline/exports`,
    userTimeline: (userId: string) => `${V1}/reports/users/${userId}/timeline/exports`,
    branches: `${V1}/branches/export`,
    groups: `${V1}/groups/export`,
    branchFiles: (branchId: string) => `${V1}/branches/${branchId}/files/export`,
    branchReports: `${V1}/reports/branches/exports`,
    branchUsers: (branchId: string) => `${V1}/branches/${branchId}/users/export`,
    userBranches: (userId: string) => `${V1}/users/${userId}/branches/export`,
    userFiles: (userId: string) => `${V1}/users/${userId}/files/export`,
    userGroups: (userId: string) => `${V1}/users/${userId}/groups/export`,
    userCourses: (userId: string) => `${V1}/users/${userId}/courses/export`,
    userCertificates: (userId: string) => `${V1}/reports/users/${userId}/certificates/exports`,
    usersExport: `${V1}/users/export`,
    languageOverridesExport: (language_code: string) =>
      `${V1}/domain/language_overrides/${language_code}/export`,
    userReportsExport: `${V1}/reports/users/exports`,
    userUnitsReportExport: (userId: string) => `${V1}/reports/users/${userId}/units/exports`,
    userCoursesReportExport: (userId: string) => `${V1}/reports/users/${userId}/courses/exports`,
    task: (taskId: string) => `${V1}/tasks/${taskId}`,
    unitsReportExport: `${V1}/reports/units/exports`,
    unitUsersReportExport: (unitId: string) => `${V1}/reports/units/${unitId}/users/exports`,
    unitTimelineReportExport: (unitId: string) => `${V1}/reports/units/${unitId}/timeline/exports`,
    branchCoursesExport: (branchId: string) => `${V1}/branches/${branchId}/courses/export`,
    myProgressCoursesExport: `${V1}/reports/users/me/courses/exports`,
    myProgressUnitsExport: `${V1}/reports/users/me/units/exports`,
    myProgressTimelineExport: `${V1}/reports/users/me/timeline/exports`,
    groupCoursesExport: (groupId: string) => `${V1}/groups/${groupId}/courses/export`,
    courseUnitsReportExport: (courseId: string) =>
      `${V1}/reports/courses/${courseId}/units/exports`,
    courseUsersReportExport: (courseId: string) =>
      `${V1}/reports/courses/${courseId}/users/exports`,
    scheduledReportsExport: `${V1}/reports/scheduled/exports`,
    customReportsExport: `${V1}/reports/custom/exports`,
    customReportExport: (reportId: string) => `${V1}/reports/custom/run/${reportId}/export/csv`,
    iltSessionsExport: (unitId: string) => `${V1}/reports/ilts/${unitId}/sessions/exports`,
    invoicesListExport: `${V1}/ecommerce/invoices/export`,
    incomingMessagesExport: `${V1}/messages/inbox/export`,
    outgoingMessagesExport: `${V1}/messages/sent/export`,
    discussionsExport: `${V1}/discussions/export`,
    ecommerceCouponsExport: `${V1}/ecommerce/coupons/export`,
    myGroupsExport: `${V1}/users/me/groups/export`,
    myFilesExport: `${V1}/users/me/files/export`,
    pendingNotificationsExport: `${V1}/notifications/pending/export`,
    notificationsHistoryExport: `${V1}/notifications/history/export`,
    notificationsOverviewExport: `${V1}/notifications/export`,
    automationsExport: `${V1}/automations/export`,
    sessionUsersExport: (sessionId: string) => `${V1}/sessions/${sessionId}/users/export`,
  },
  excelExports: {
    userOverview: (userId: string) => `${V1}/reports/users/${userId}/exports`,
    branchOverview: (branchId: string) => `${V1}/reports/branches/${branchId}/exports`,
    groupOverview: (groupId: string) => `${V1}/reports/groups/${groupId}/exports`,
    unitMatrix: (courseId: string) => `${V1}/reports/courses/${courseId}/unit-matrix/exports`,
    trainingMatrix: `${V1}/reports/training-matrix/exports`,
    singleCourseReport: (courseId: string) => `${V1}/reports/courses/${courseId}/exports`,
    assignmentOverview: (assignmentId: string) =>
      `${V1}/reports/assignments/${assignmentId}/exports`,
    iltOverview: (iltId: string) => `${V1}/reports/ilts/${iltId}/exports`,
    trainingProgress: `${V1}/reports/exports`,
    scormOverview: (scormId: string) => `${V1}/reports/scorms/${scormId}/exports`,
    surveyOverview: (surveyId: string) => `${V1}/reports/surveys/${surveyId}/exports`,
    testOverview: (testId: string) => `${V1}/reports/tests/${testId}/exports`,
    customReport: (reportId: string) => `${V1}/reports/custom/run/${reportId}/export/excel`,
  },
  gradingHub: {
    assignments: {
      getSingle: (unitId: string, userId: string) => `${V1}/assignments/${unitId}/users/${userId}`,
      submissions: `${V1}/assignments/submissions`,
      unitSubmissions: (unitId: string) => `${V1}/assignments/${unitId}/submissions`,
      massActions: {
        reset: {
          count: `${V1}/grading-hub/mass-actions/reset/count`,
          reset: `${V1}/grading-hub/mass-actions/reset`,
        },
        grade: {
          count: `${V1}/grading-hub/mass-actions/grade/count`,
          grade: `${V1}/grading-hub/mass-actions/grade`,
        },
        addToSession: {
          count: (sessionId: string) => `${V1}/sessions/${sessionId}/users/mass-add/count`,
          addToSession: (sessionId: string) => `${V1}/sessions/${sessionId}/users/mass-add`,
        },
        removeFromSession: {
          count: (sessionId: string) => `${V1}/sessions/${sessionId}/users/mass-remove/count`,
          removeFromSession: (sessionId: string) => `${V1}/sessions/${sessionId}/users/mass-remove`,
        },
      },
    },
    count: `${V1}/grading-hub/pending-count`,
    grade: (unitId: string, userId: string) => `${V1}/units/${unitId}/users/${userId}/grade`,
    reset: (unitId: string, userId: string) => `${V1}/units/${unitId}/users/${userId}/reset-status`,
  },
  courseStore: {
    course: (courseId: string) => `${V1}/talentlibrary/courses/${courseId}`,
    units: (courseId: string) => `${V1}/talentlibrary/courses/${courseId}/units`,
    courses: `${V1}/talentlibrary/courses`,
    categories: `${V1}/talentlibrary/categories`,
    acquire: (courseId: string) => `${V1}/talentlibrary/courses/${courseId}/acquire`,
    massAcquire: `${V1}/talentlibrary/courses/mass-acquire`,
    massAcquireCount: `${V1}/talentlibrary/courses/mass-acquire/count`,
  },
  onboardingSettings: {
    settings: `${V1}/welcome-questions`,
    dismissTour: `${V1}/tour/dismiss`,
    restartTour: `${V1}/tour`,
  },
  ilts: {
    sessions: `${V1}/ilts/sessions`,
    sessionsUsers: (sessionId: string) => `${V1}/sessions/${sessionId}/users`,
    getSingle: (unitId: string, userId: string) => `${V1}/ilts/${unitId}/users/${userId}`,
    getUnenrolledUsers: (unitId: string) => `${V1}/sessions/${unitId}/unenrolled-users`,
    printAttendance: (sessionId: string) => `${V1}/sessions/${sessionId}/print-attendance`,
  },
  help: {
    accountManager: {
      create: `${V1}/help/support/account-manager`,
      attachment: `${V1}/help/support/account-manager/attachment`,
    },
    articles: `${V1}/help/articles`,
    supportServices: `${V1}/help/support/services`,
    ticket: {
      create: `${V1}/help/support/ticket`,
      attachment: `${V1}/help/support/ticket/attachment`,
    },
    zendeskConfigurationData: `${V1}/help/chat/token`,
  },
  integrations: {
    zoom: `${V1}/integrations/zoom`,
  },
  tour: {
    newInterfaceDismiss: `${V1}/tour/new-interface/dismiss`,
  },
  craft: {
    credits: `${V1}/ai-credits`,
    creditCatalog: `${V1}/craft/catalog`,
    creditRequest: `${V1}/ai-credits/request`,
    commit: (unitId: string) => `${V1}/units/${unitId}/craft/commit`,
    disclaimer: `${V1}/users/me/craft-disclaimer`,
  },
  tagManager: {
    track: `${V1}/domain/settings/track-tag-manager`,
  },
} as const;
